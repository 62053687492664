<template>
    <div>
        <div v-for="product in products" :key="product._id" v-show="parseInt(product.price?.replace('.','')) < 9999">
            <b-row class="m-0 gap-2">
                <b-col class="p-0 d-flex align-items-center position-relative" cols="4">
                    <b-img class="w-100" :src="product.imageLink" @error="imageError(product)" v-if="product.imageLink"></b-img>
                    <b-skeleton-img class="pe-none" no-aspect animation="" v-else></b-skeleton-img>
                    <div class="position-absolute start-0 top-0 m-3" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Adicionar à lista">
                        <b-iconstack class="darkenTextOnHover" font-scale="1.75" @click="productsList(product)" role="button">
                            <b-icon :class="product.listed ? 'text-danger' : 'text-purple'" stacked icon="circle-fill"></b-icon>
                            <b-icon stacked class="text-white" icon="heart" scale="0.5"></b-icon>
                        </b-iconstack>
                    </div>
                </b-col>
                <b-col class="p-2 d-flex flex-column justify-content-center">
                    <div class="fs-6 mb-1 fw-semibold text-capitalize">
                        {{ product.displayTitle?.toLowerCase() }}
                    </div>
                    <div class="text-body-tertiary text-uppercase">
                        Cód: {{ product.id }}
                    </div>
                    <div class="text-purple">
                        <div class="vstack lh-1" v-if="product.salePrice">
                            <div class="smaller-text">
                                De:
                                <span class="text-decoration-line-through">
                                    {{ product.price }}
                                </span>
                            </div>
                            <div>
                                <span class="fw-bold fs-5">
                                    R$ {{ product.salePrice }}
                                </span>
                            </div>
                        </div>
                        <div class="text-purple fw-bold fs-5" v-else>
                            R$ {{ product.price }}
                        </div>
                        <!-- <span class="smaller-text">
                            com pix
                        </span> -->
                    </div>
                    <div class="d-flex align-items-center text-purple gap-2 mt-1">
                        <b-button class="text-uppercase bg-purple text-white rounded-pill px-3" @click="productCart(product)" style="font-size: 0.9em">
                            <b-icon icon="cart4"></b-icon>
                            <span v-if="product.carted">
                                Remover
                            </span>
                            <span v-else>
                                Adicionar
                            </span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <div class="bg-purple text-white p-2" @click="product.showDescription ? product.showDescription = false : product.showDescription = true; refreshProducts()" role="button">
                <div v-if="product.showDescription">
                    <div class="text-uppercase">Descrição</div>
                    <div>
                        {{ product.description }}
                    </div>
                </div>
                <div class="text-uppercase text-center" v-else>
                    Descrição
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'operator-products',
    props: [
        'products'
    ],
    methods: {
        productsList(product) {
            product.listed = product.listed ? false : true
            this.$root.$emit('products_list',product)
            this.refreshProducts()
        },
        productCart(product) {
            product.carted = product.carted ? false : true
            this.$root.$emit('product_cart',product)  
            this.refreshProducts()
        },
        refreshProducts() {
            this.products.push({})
            this.products.pop()
        },
        imageError(product) {
            // console.log({product})
            product.imageLink = null
            this.refreshProducts()
        }
    }
}
</script>